import { Link, useNavigate } from "react-router-dom";
import BusinessTypeSelect from "../business-type-select/BusinessTypeSelect";
import CitySelect from "../city-select/CitySelect";
import PropertyTypeSelect from "../property-type-select/PropertyTypeSelect";
import Css from "./PropertySearchForm.module.css";
import { useRef, useState } from "react";
import { encodePropertyVars } from "../../../utils/url-utils";

export default function PropertySearchForm(props) {

    const [codeSearch, setCodeSearch] = useState(false);
    const codeInputRef = useRef(null);
    const [propType, setPropType] = useState(null);
    const [businessType, setBusinessType] = useState(null);
    const [city, setCity] = useState(null);
    const navigate = useNavigate();
    const [codeValidationStr, setCodeValidationStr] = useState("");

    const submitHandler = (e) => {
        e.preventDefault();
        let redirUrl = "/propiedades/"
        if(codeSearch) {
            validateCode()
            const codeStr = codeInputRef.current.value;
            const codeInt = parseInt(codeStr);
            if(isNaN(codeInt)) {
                return;
            }
            redirUrl += codeInt + "/";
            // Cannot use navigate hook as it would cause an error on PropertyDetail component
            // when trying to access the code from the URL
            window.location.href = redirUrl;
        } else {
            if(propType) {
                redirUrl += encodePropertyVars(propType.label) + "/";
            }
            if(businessType) {
                redirUrl += encodePropertyVars(businessType.label) + "/";
            }
            if(city) {
                redirUrl += encodePropertyVars(city.label) + "/";
            }
            navigate(redirUrl);
        }
    }

    const validateCode = () => {
        const codeStr = codeInputRef.current.value;
        const codeInt = parseInt(codeStr);
        if(isNaN(codeInt)) {
            setCodeValidationStr("El código debe ser un número entero");
        } else {
            setCodeValidationStr("");
        }
    }

    const toggleCodeSearch = (e) => {
        e.preventDefault();
        if(!codeSearch) {
            setCodeSearch(true);
            setPropType(null);
            setBusinessType(null);
            setCity(null);
        } else {
            setCodeValidationStr("");
            codeInputRef.current.value = "";
            setCodeSearch(false);
        }
    }


    return(
        <form className={Css.wrapper + " " + props.className} onSubmit={submitHandler}>
            <div className={Css.outerDiv}>
                <div className="d-flex justify-content-center row mx-0">
                    <h2 className={"text-size-4 h1 text-center " + Css.title}>Encuentra el inmueble de tus sueños</h2>
                </div>
                <div className="row mx-0">
                    {
                        codeSearch ? (
                            <>
                                <div className={"col-12 col-lg " + Css.selectWrapper}>
                                    <input ref={codeInputRef} type="text" className="form-control" placeholder="Código inmueble"/>
                                    {codeValidationStr !== "" ? <div className={Css.codeValStr}>{codeValidationStr}</div> : null }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={"col-12 col-md-4 col-lg " + Css.selectWrapper}>
                                    <PropertyTypeSelect setPropType={setPropType} />
                                </div>
                                <div className={"col-12 col-md-4 col-lg " + Css.selectWrapper}>
                                    <BusinessTypeSelect setBusinessType={setBusinessType} />
                                </div>
                                <div className={"col-12 col-md-4 col-lg " + Css.selectWrapper}>
                                    <CitySelect
                                        setCity={setCity}
                                        businessType={businessType}
                                        propType={propType}
                                    />
                                </div>
                            </>
                        )
                    }
                    <div className={"col-12 col-lg-auto " + Css.searchBtnWrapper}>
                        <button className={Css.searchBtn}>Buscar</button>
                    </div>
                </div>
                <div>
                    <a className={Css.advancedSearch} onClick={toggleCodeSearch}>
                        {codeSearch ? "<< Búsqueda convencional" : "Búsqueda por código >>"}
                    </a>
                </div>
            </div>
        </form>
    )
}